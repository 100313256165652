<template>
  <div>
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right div40">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>乡贤管理</el-breadcrumb-item>
        <el-breadcrumb-item>登记管理</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="table">
        <div class="fl">
          <!--                    <el-button type="primary" size="small" @click="Apply">申请修改</el-button>-->
          <span
            class="biaoji"
            v-if="applystatus !== 0 && applystatus !== 3"
            v-html="
              applystatus === 1
                ? '修改申请已提交，等待管理员同意…'
                : applystatus === 2
                ? '申请通过'
                : '已驳回'
            "
          ></span>
          <el-button
            type="primary"
            plain
            size="small"
            v-else
            @click="Apply(1)"
            v-html="applystatus > 2 ? '已驳回 重新申请' : '申请修改'"
          ></el-button>

          <div class="hr-10"></div>
        </div>
        <div class="fr">
          <el-button
            type="primary"
            plain
            size="small"
            icon="el-icon-plus"
            @click="showCreateDialog"
            >新增乡贤</el-button
          >
          <el-button
            size="small"
            type="success"
            icon="el-icon-check"
            @click="Apply(0)"
            v-if="applystatus === 2"
            >完成修改</el-button
          >
          <el-button
            type="warning"
            plain
            size="small"
            icon="el-icon-upload2"
            @click="dialogUpload = true"
            >批量导入</el-button
          >
        </div>
        <div class="mask" v-show="disabled"></div>
        <el-table :data="tableData" :stripe="false">
          <el-table-column
            type="index"
            label="序号"
            width="50"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="80"
            align="center"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="50" align="center">
            <template slot-scope="scope">
              <span v-html="scope.row.sex === '0' ? '女' : '男'"></span>
            </template>
          </el-table-column>
          <el-table-column label="出生年月" width="100" align="center">
            <template slot-scope="scope">{{
              scope.row.birthday | formatDate
            }}</template>
          </el-table-column>
          <el-table-column
            prop="academic"
            label="学历学位"
            width="100"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="occupation"
            label="现工作单位及职务"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="honours"
            label="获得的最高荣誉称号或入选人才 工作情况"
            width="150"
            align="center"
            min-width="320px"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="contact"
            label="联系方式（手机、固定电话）"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column label="家庭情况及联系方式" width="450">
            <el-table-column
              prop="familyName"
              label="在赣州主要亲属（或者朋友）姓名"
              width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="city"
              label="地级市"
              width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="familyCompany"
              label="单位或住址"
              width="150"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="familyContact"
              label="联系电话"
              width="150"
              align="center"
            ></el-table-column>
          </el-table-column>
          <el-table-column
            prop="type"
            label="人才类型"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="township"
            label="所在乡（镇、街道、管理处）"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="village"
            label="所在区（社区）"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120"
            align="center"
          >
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                size="small"
                icon="el-icon-edit-outline"
              ></el-button>
              <el-button
                type="danger"
                @click="handledelete(scope.row)"
                size="small"
                v-if="applystatus === 2"
                icon="el-icon-delete"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="div-btn">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="currentchange"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      v-if="createDialog"
      :visible.sync="createDialog"
      min-width="900px"
      width="70%"
      :before-close="handleClose"
    >
      <el-card shadow="never">
        <div slot="header" class="header-div">
          <span style="font-size: 20px;font-weight:bold; ">乡贤新增/查看</span>
        </div>

        <div>
          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            label-position="left"
            label-width="auto"
            style="text-align: left;"
            :disabled="applystatus !== 2"
          >
            <v-row>
              <v-col cols="4">
                <el-form-item label="姓名" prop="name">
                  <el-input v-model="form.name" placeholder="姓名"></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="4">
                <el-form-item label="性别" prop="sex">
                  <el-radio-group v-model="form.sex">
                    <el-radio-button label="1">男</el-radio-button>
                    <el-radio-button label="0">女</el-radio-button>
                  </el-radio-group>
                </el-form-item>
              </v-col>
              <v-col cols="4">
                <el-form-item label="出生年月" prop="birthday">
                  <el-date-picker
                    v-model="form.birthday"
                    type="date"
                    :picker-options="pickerOptions"
                    placeholder="出生年月"
                    style="width: 184px;"
                  >
                  </el-date-picker>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <el-form-item label="学历学位" prop="academic">
                  <el-select v-model="form.academic" placeholder="请选择">
                    <el-option
                      v-for="item in academic"
                      :key="item.value"
                      :label="item.label"
                      :value="item.academic"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="4">
                <el-form-item label="工作单位" prop="occupation">
                  <el-input
                    v-model="form.occupation"
                    placeholder="现工作单位及职务"
                  ></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="4">
                <el-form-item label="所获荣誉" prop="honours">
                  <el-input
                    v-model="form.honours"
                    placeholder="所获荣誉"
                  ></el-input>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <el-form-item label="联系方式(固定电话)" prop="contact">
                  <el-input
                    v-model="form.contact"
                    placeholder="联系电话"
                  ></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="4">
                <el-form-item label="乡镇" prop="township">
                  <el-input
                    v-model="form.township"
                    placeholder="乡镇"
                  ></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="4">
                <el-form-item label="村(社区)" prop="village">
                  <el-input
                    v-model="form.village"
                    placeholder="村（社区）"
                  ></el-input>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <el-form-item label="人才类型" prop="type">
                  <el-select v-model="form.type" placeholder="请选择人才类型">
                    <el-option
                      v-for="item in options"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name"
                    >
                    </el-option>
                  </el-select>
                  <!--                            <el-input v-model="form.type" placeholder="人才类型"></el-input>-->
                </el-form-item>
              </v-col>
              <v-col cols="4">
                <el-form-item
                  label="现工作(生活)所在地级市"
                  prop="familyCompany"
                >
                  <el-select
                    v-model="form.city"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option-group
                      v-for="group in citys"
                      :key="group.label"
                      :label="group.label"
                    >
                      <el-option
                        v-for="item in group.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-option-group>
                  </el-select>
                </el-form-item>
              </v-col>
              <v-col cols="4">
                <el-form-item label="单位或住址" prop="familyCompany">
                  <el-input
                    v-model="form.familyCompany"
                    placeholder="单位或住址"
                  ></el-input>
                </el-form-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <el-form-item label="联系电话" prop="familyContact">
                  <el-input
                    v-model="form.familyContact"
                    placeholder="联系电话"
                  ></el-input>
                </el-form-item>
              </v-col>
              <v-col cols="4">
                <el-form-item
                  label="在赣州主要亲属（或者朋友）姓名"
                  prop="familyName"
                >
                  <el-input
                    v-model="form.familyName"
                    placeholder="姓名"
                  ></el-input>
                </el-form-item>
              </v-col>
            </v-row>
          </el-form>

          <el-button @click="handleClose">取消</el-button>
          <el-button
            type="primary"
            @click="requestCreate('form')"
            v-show="!disabled"
            >保存</el-button
          >
        </div>
      </el-card>
    </el-dialog>

    <el-dialog title="乡贤批量导入" :visible.sync="dialogUpload" width="30%">
      <el-upload
        class="upload-demo"
        ref="upload"
        :action="host + '/worthy/importExcel'"
        :on-preview="handlePreview"
        :on-success="handleSuccess"
        :data="UploadData"
        accept=".xlsx"
        :file-list="fileList"
        :auto-upload="false"
      >
        <el-button slot="trigger" size="small" type="primary"
          >选取文件</el-button
        >
        <el-button
          style="margin-left: 10px;"
          size="small"
          type="success"
          @click="submitUpload"
          >上传到服务器</el-button
        >
        <div slot="tip" class="el-upload__tip">
          只能上传.xlsx文件
        </div>
      </el-upload>
    </el-dialog>
  </div>
</template>

<script>
import configDate from "../../../utils/config";
export default {
  data() {
    return {
      fileList: [],
      host: configDate.url,
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() >= Date.now() - 8.64e7;
        },
      },
      dialogUpload: false,
      options: [
        { name: "A+类（产业领军人才）", id: "value1" },
        { name: "A类（国内外顶尖人才 ）", id: "value2" },
        { name: "B类（国家级高层次人才）", id: "value3" },
        { name: "C类（省级高层次人才 ）", id: "value4" },
        { name: "D类（市级高层次人才）", id: "value5" },
        { name: "E类（行业急需紧缺人才）", id: "value6" },
        { name: "F类（青年英才）", id: "value7" },
        { name: "G类（产业工匠）", id: "value8" },
      ],
      citys: [],
      catalog: false,
      loading: false,
      disabled: true,
      applystatus: 0,
      ID: sessionStorage.getItem("ID") || "",
      UploadData: {
        creatorId: sessionStorage.getItem("ID") || "",
      },
      CkGx: false,
      editing: {},
      //  创建弹窗与进度
      createDialog: false,
      createProgress: false,
      academic: configDate.education,
      tableData: [],
      township: "",
      form: {
        city: "",
        academic: "", //学历/学位
        birthday: "", //生日
        contact: "", //联系电话
        creator: "", //创建者
        familyCompany: "",
        familyContact: "",
        familyName: "",
        honours: "", //所获荣誉
        name: "", //姓名
        occupation: "", //工作单位及职务
        sex: 1, //性别
        township: "", //乡镇
        type: "", //人才类型
        village: "", //村（社区）
      },
      rules: {
        academic: [
          { required: true, message: "请填写学历/学位", trigger: "change" },
        ], //学历/学位
        birthday: [
          {
            type: "date",
            required: true,
            message: "请填写生日",
            trigger: "change",
          },
        ], //生日
        contact: [
          { required: true, message: "请填写联系电话", trigger: "blur" },
        ], //联系电话
        familyCompany: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
        familyContact: [
          { required: true, message: "不能为空", trigger: "blur" },
        ],
        familyName: [{ required: true, message: "不能为空", trigger: "blur" }],
        honours: [
          { required: true, message: "请填写所获荣誉", trigger: "blur" },
        ], //所获荣誉
        name: [{ required: true, message: "请填写姓名", trigger: "blur" }], //姓名
        occupation: [
          { required: true, message: "请填写工作单位及职务", trigger: "blur" },
        ], //工作单位及职务
        sex: [{ required: true, message: "请填写性别", trigger: "blur" }], //性别
        township: [{ required: true, message: "请填写乡镇", trigger: "blur" }], //乡镇
        type: [{ required: true, message: "请填写人才类型", trigger: "blur" }], //人才类型
        village: [
          { required: true, message: "请填写村（社区）", trigger: "blur" },
        ], //村（社区）
      },
    };
  },
  created() {
    this.getList();
    configDate.provinces.forEach((ite) => {
      this.citys.push({
        label: ite.provinceName,
        options: ite.citys,
      });
    });
    //console.log(this.citys);
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },
    showCreateDialog() {
      this.form = {
        academic: "", //学历/学位
        birthday: "", //生日
        contact: "", //联系电话
        creator: "", //创建者
        familyCompany: "",
        familyContact: "",
        familyName: "",
        honours: "", //所获荣誉
        name: "", //姓名
        occupation: "", //工作单位及职务
        sex: 1, //性别
        township: this.township, //乡镇
        type: "", //人才类型
        village: "", //村（社区）
      };
      this.applystatus = 2;
      this.editing = {};
      this.disabled = false;
      this.createDialog = true;
    },
    requestCreate(formName) {
      const self = this;
      if (this.CkGx) {
        this.form.status = 110;
        this.$axios.put("/worthy", this.form).then(function(res) {
          if (res.data.status === 200) {
            self.createDialog = !self.createDialog;
            self.getList();
          }
        });
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            self.form.creator = self.ID;
            self.$axios.post("/worthy", this.form).then(function(res) {
              if (res.data.status === 200) {
                self.createDialog = false;
                self.getList();
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    getList(page = 1) {
      const self = this;
      this.$axios
        .get("/worthy/page", {
          params: {
            current: page,
            creator: self.ID,
            size: 8,
          },
        })
        .then(function(res) {
          self.getTyle();

          self.total = res.data.data.total;
          self.tableData = res.data.data.records;
          self.disabled = true;
        });
    },
    //分页
    currentchange(page) {
      this.getList(page);
    },
    getTyle() {
      const self = this;
      this.$axios
        .get("/worthy/manager", {
          params: {
            id: self.ID,
          },
        })
        .then(function(res) {
          console.log(res.data.data);
          self.township = res.data.data.township;
          console.log(self.form);
          self.applystatus = res.data.data.apply.status;
          if (self.applystatus > 1) {
            self.disabled = false;
          }
        });
    },
    Apply(num) {
      const self = this;
      let appid = {
        owner: this.ID,
        status: num,
      };
      this.$confirm("修改信息需管理员同意，确定提交申请吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          self.$axios.post("/worthy/apply", appid).then(function(res) {
            if (res.data.status === 200) {
              self.getList();
            }
          });
        })
        .catch(() => {});
    },
    //查看
    handleClick(row) {
      this.CkGx = true;
      this.form = row;
      this.createDialog = true;
    },
    //关闭
    handleClose() {
      this.form = {
        academic: "", //学历/学位
        birthday: "", //生日
        contact: "", //联系电话
        creator: "", //创建者
        familyCompany: "",
        familyContact: "",
        familyName: "",
        honours: "", //所获荣誉
        name: "", //姓名
        occupation: "", //工作单位及职务
        sex: 1, //性别
        township: "", //乡镇
        type: "", //人才类型
        village: "", //村（社区）
      };

      this.disabled = this.applystatus <= 1;
      this.CkGx = false;
      this.createDialog = !this.createDialog;
      this.getList();
    },
    handledelete(row) {
      const self = this;
      this.$confirm("确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.delete("/worthy?id=" + row.id).then(function(res) {
            if (res.data.status === 200) {
              self.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleSuccess(file, fileList) {
      console.log(file, fileList);
      if (file.status == 200) {
        this.dialogUpload = false;
        this.$message({
          type: "success",
          message: "导入成功",
        });
      }
    },
    handlePreview(file) {
      console.log(file);
    },
  },
};
</script>

<style lang="less" scoped>
.div-text {
  color: #1091ed;
  padding: 8px;
}
.div-btn {
  height: 126px;
  padding: 50px 0;
}
hr {
  height: 2px;
  background-color: #1091ed;
}
.table {
  position: relative;
}
.mask {
  position: absolute;
  top: 35px;
  cursor: not-allowed;
  left: 0;
  z-index: 9;
  width: calc(100% - 100px);
  height: calc(100% - 50px);
  background: #fff;
  opacity: 0.1;
}
.el-form-item .el-form-item__label {
  line-height: 14px;
}
.rowClass {
  background-color: red;
}
.biaoji {
  background: #fbd196;
  padding: 4px 10px;
  color: #e08300;
  font-size: 14px;
  border-radius: 4px;
}
</style>
